.side-dash {
    background: #e2dcdc;
    color: #e9e9e9;
    position: relative;
}

ul.sidebarList li{
    margin-top: 5px;
    margin-bottom: 10px;
}

.sidebarWrapper {
    padding: 10px;
}
.slim-side{
    width: 100px;
}
.full-side {
    width: 250px;
}
.sidebarMenu ul li a {
    color: #d52c31;
    text-decoration: none;
    font-weight: 600;
    width: 100%;
    display: block;
    padding: 12px 10px;
    margin-bottom: 1px;
  }
.sidebarMenu ul li a:hover{
    background-color: #d52c31;
    color: #f6f6f6;
}
.alerter {
  padding:10px
}
span.name-Item {
  padding-left: 10px;
}
span.sd-icon {
    padding: 10px;
}
h3.sidebarTitle.logo-spark {
    padding: 20px;
    text-align: center;
}