
.top-nav {
    background: #fff;
    padding: 15px 20px;
    font-size: 11px;
    font-weight: 600;
    height: 70px;
    border-bottom: 1px solid #e5e5e5;
}
.nav-bar {
    display: flex;
    justify-content: space-between;
}
.nav-section-part a {
    margin-left: 10px;
}

