body .logo-base{
    background-color: #fcefe9;
}
.reg-header {
    padding: 10px 50px;
    display: flex;
    justify-content: space-between;
}
img.logo-one {
    width: 180px;
}
.btn-rescure-login {
    background: #C73D39;
    padding: 10px 20px;
    border-radius: 15px;
    color: #fff;
    font-size: 15px;
    font-weight: 800;
}
.main-login-data {
    padding: 45px 60px;
    display: flex;
}
.frm-area {
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    width: 400px;
    margin-top: 20%;
    margin-left: 20%;
}
.data-image-block {
    margin-left: 15%;
}
.log-start {
    height: 100vh;
}
.reg-left-image{
    background-position: center;
    background-size: cover;
    width: 40%;
    height: 100vh;
}
.reg-right-form-data {
    flex: 1 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}
.reg-main {
    width: 400px;
    margin-right: 30px;
}
.head-right-host {
    text-align: right;
}
.head-right-host-reg {
    text-align: right;
    position: absolute;
    padding: 10px 50px;
    width: 100%;
}
.flexregister {
    display: flex;
}
.reg-form {
    padding: 5px;
}
.frm-area form {
    padding-top: 20px;
    padding-bottom: 20px;
}
.position-navigate {
    padding: 15px 40px;
    position: fixed;
    z-index: 10000;
    bottom: 1px;
    width: 100%;
    text-align: right;
}

.mgright {
    margin-right: 10px;
}
/* Fade transition */
.fade-enter {
    opacity: 0;
    transform: translateY(20px);
}

.fade-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 900ms, transform 900ms;
}

.fade-exit {
    opacity: 1;
    transform: translateY(0);
}

.fade-exit-active {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 900ms, transform 900ms;
}
.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
    background: #C73D39;
    border-color: #C73D39;
}
/*LOGIN Block faders*/
.frm-area, .forgot-password-area {
    transition: opacity 0.5s ease-in-out;
    opacity: 1;
}

.frm-area.fade-in {
    opacity: 1;
}

.frm-area.fade-out {
    opacity: 0;
}

.forgot-password-area.fade-in {
    opacity: 1;
    transition: opacity 0.5s ease-in-out 0.5s; /* Delayed transition to allow frm-area to fade out first */
}

.login-forgot-pass {
    cursor: pointer;
    font-size: 10px;
    font-weight: 700;
    text-align: center;
    color:#C73D39;
}
.forgot-password-area {
    position: absolute;
    top: 25%;
    opacity: 0;
    background: #fff;
    padding: 40px;
    border-radius: 10px;
    width: 400px;
}
/* END LOGIN */
.mini-processing {
    width: 70px;
    position: absolute;
    top: 60%;
    left: 15%;
}
.forgot-password-area.fade-out {
    left: -50%;
}
@media only screen and (max-width: 480px) {

    .main-login-data {
        display: block;
        width: 100%;
        padding: 7px;
    }
    .data-image-block {
        margin-left: 0%;
    }
    img.logo-on-display {
        width: 330px;
        margin-top: 25px;
    }

    .frm-area {
        background: #fff;
        border-radius: 10px;
        margin-left: 0%;
        margin-top: 20%;
        padding: 20px;
        width: 360px;
    }
    .reg-left-image {
        width: 10px;
    }
    .reg-main {
        margin-right: 0px;
        width: 310px;
    }

    .form-block .frm-area h2.title-login {
        font-size: 25px;
    }
    .form-block .frm-area h4 {
        font-size: 15px;
    }
    .reg-header {
        padding: 10px 20px;
    }
}
@media only screen and (max-width: 321px) {

}
