body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  padding:0;
  font-family: 'Open Sans', sans-serif;
}
.dashingboard {
  background-color: #fcefe9;
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input.form-control, select.form-control, textarea.form-control {
  background: #dedede;
  font-size: 14px;
  border-radius: 10px;
}

.sidebarMenu ul {
  list-style: none;
  padding: 0px;
}

.main-dash-content {
  width: 100%;
}
.main-content-arena {
  padding: 30px;
}
.flexme {
  display: flex;
}
.flexfit50 {
  width: 50%;
}
.flexfit70 {
  width: 70%;
}
.flexfit30 {
  width: 30%;
}
.sect-box {
  padding: 40px 0;
  border-bottom: 1px solid #414042;
  margin-bottom: 25px;
}
h2.lbl-title-page {
  color: #414042;
  font-size: 25px;
}
.access-container {
  text-align: center;
  padding: 60px;
}
.stitle {
  font-size: 14px;
  padding-top: 9px;
}
.showcontent input.form-control {
  font-weight: 300;
  background: #fff;
  pointer-events: none;
  padding: 0px;
  border: 1px solid #fff;
}
h4.lbl-title-inner {
  font-style: italic;
  font-size: 17px;
  letter-spacing: 1px;
}
.btn-gray {
  border: 2px solid gray;
  border-radius: 18px;
  color: gray;
  padding: 5px 20px;
}
.btn-blacky {
  background: #131010;
  padding: 9px 25px;
  border-radius: 23px;
  color: #fff;
  font-size: 15px;
  font-weight: 800;
  border: 0px;
}
.btn-blacky:hover{
  background: #000000 !important;
  color: #fff;
}
.btn-rescure {
  background: #C73D39;
  padding: 9px 25px;
  border-radius: 23px;
  color: #fff;
  font-size: 15px;
  font-weight: 800;
  border: 0px;
}
.btn-rescure:hover {
  background: #de5858;
}
.p-button:enabled:hover, .p-button:not(button):not(a):not(.p-disabled):hover {
  background: #de5858 !important;
  border-color: #de5858;
}
.btn.btn-green {
  background: #07b740;
  border: #07b740;
  color: #fff;
  border-radius: 25px;
  padding: 10px 25px;
}
.btn.btn-green:hover {
  background: #16a63c !important;
  border: #16a63c;
}
.chat-area-request-chat {
  padding: 15px;
}
.p-dialog-header {
  background: #e5e5e5 !important;
}
.p-dialog-footer {
  background: #e5e5e5 !important;
  padding-top: 15px !important;
}
.modal-body-content {
  padding: 15px 0px;
}
.btn-rescure.btncircle {
  padding: 4px 16px 7px;
  border-radius: 50%;
  font-size: 23px;
}
.btn-mulstep {
  background: #4b5a6a;
  color: #fff;
}
button.btn.selection-buttons {
  border: 3px #C73D39 solid;
  border-radius: 25px;
  color: #C73D39;
  padding: 10px 20px;
  font-size: 12px;
  font-weight: 700;
}
button.btn.selection-buttons.cashmtrue {
  background: #16a63c;
  color: #fff;
  border: 3px #16a63c solid;
}
.btsm1 {
  font-size: 10px;
}
.btsm2 {
  font-size: 12px;
}
.btn-full {
  width: 100%;
}
.btn-transfer {
  background: #bcbaba;
  border-radius: 50%;
  padding: 6px 10px;
}
.row-buttons {
  padding: 5px 0px;
  justify-content: space-between;
  display: flex;
}
.row.rstep {
  padding: 14px;
}
.md-label span {
  padding-left: 6px;
  margin-right: 20px;
}

.mgright5 {
  margin-right: 5px;
}
.mgleft5{
  margin-left: 5px;
}
.mgtop10{
  margin-top: 10px;
}
.mgtop30{
  margin-top: 30px;
}
img.proc {
  width: 120px;
  position: fixed;
  left: 40%;
  bottom: 30%;
  z-index: 10000;
}
img.inn-process {
  width: 45px;
  z-index: 10000;
}
img.inn-process.small-img {
  position: absolute;
  top: 20px;
  right: 10px;
}
span.radItemPack {
  padding-left: 5px;
  margin-right: 20px;
}
.prel {
  position: relative;
}
.password-eye {
  position: absolute;
  right: 1px;
  bottom: 3px;
}
.no-style-button1 {
  background: transparent;
  border: none;
}
.fit-box {
  background: #fcfcfc;
}
.pd10{
  padding: 10px;
}
.pd20{
  padding: 20px;
}
.ptlr {
  padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}
.row-box {
  padding: 10px 0px;
}
.data-sectionarea {
  padding: 15px 10px;
}
.topborder {
  border-top: 1px solid #e8e8e8;
}
ul.list-items-cont li {
  background: #303030;
  border-radius: 7px;
  font-size: 14px;
  list-style: none;
  margin-bottom: 2px;
  padding: 8px;
  color: #fff;
}
ul.list-items-cont li:hover {
  background: #424141;
}
ul.list-items-cont li button.btn.btn-close-ls {
  background: #faf9f9;
}
span.litext {
  width: 90%;
  display: inline-block;
}
.txtTodo1{
  width: 65%;
  display: inline-block;
}

.tbl-nostyle-full {
  width: 100%;
}
table.tbrspaces tr td {
    padding: 10px 5px;
}
table.tbcellthird tr td {
    width: 33%;
}
.sm-label {
    font-size: 10px;
    font-weight: 600;
}
.mp-prescript-item {
    padding: 5px 10px;
    margin-right: 5px;
    margin-bottom: 5px;
    font-size: 13px;
    border-radius: 3px;
    width: 100%;
    font-style: italic;
}
  ul.list-items-cont {
    padding: 2px;
  }

.stat {
    text-align: center;
    padding: 2px;
    border-radius: 3px;
    font-size: 12px;
}
.statInitialized{
    border: 1px solid #fef0d8;
    background: #fff9ee;
    color: #414042;
}
.statGenerated{
    border: 1px solid #d8fee1;
    background: #eefff1;
    color: #414042;
}
.statDownloaded{
    border: 1px solid #d8effe;
    background: #eef8ff;
    color: #414042;
}
.statCancelled{
    border: 1px solid #fed8d8;
    background: #ffeeee;
    color: #a10000;
}
.stat.statExpired {
    background: #faeeff;
    border: 1px solid #ddbbff;
    color: #66566c;
  }
  .txtright {
    text-align: right;
  }
  .btn.btnsml3 {
    font-size: 10px;
    padding: 9px 18px;
  }
  /* About Start */
  .initial-stipe {
    background: #eee1e1;
    padding: 50px 10px;
  }
  .tt1-sub-1 {
    font-size: 20px;
    color:#C73D39;
    font-weight: 800;
  }
  .tt2-sub-1 {
    font-size: 40px;
    font-weight: 600;
  }
  .tt2-sub-2 {
    font-weight: 100;
    font-size: 15px;
    padding: 25px;
    text-align: center;
    color:#414042;
  }
  .tt2-sub {
    text-align: center;
    font-size: 30px;
    padding: 10px;
    color: #414042;
  }
  .content-text-sect {
    margin-top: 30px;
    font-size: 13px;
  
  }
  .rsq-section {
    display: block;
    position: relative;
    padding: 50px 0px;
    background-color: #fcefe9;
    color: #414042;
  }
  .block-bt {
    padding: 30px 10px;
    background: #fff5f5;
    text-align: center;
    border-radius: 11px;
  }
  /*About End*/
  .radio-group.meditems label {
    margin-right: 15px;
  }
  .radio-group.meditems input[type="radio"] {
    margin-right: 5px;
  }
  .list-prescript {
    position: relative;
  }
  .part-prescript {
    display: flex;
    margin-bottom: 8px;
  }
  .wide100{
    width: 100%;
  }
  .wide80{
    width:80%
  }
  .posi-close {
    background: #2e2e2e;
    color: #fff;
    padding: 4px 12px !important;
    border-radius: 10px;
  }
  .posi-close:hover{
    background-color:#C73D39;
  }
  span.span-label {
    font-weight: 200;
  }
  .dotted-mods {
    padding: 10px;
    border: 1px dotted #c2c2c2;
    min-height: 145px;
    background-color: #fcefe9;
  }
  img.logo-on-dashboard {
    width: 75%;
    position: relative;
  }
  .foot-display {
    border-top: 1px solid #eaeaea;
    padding-top: 10px;
    margin-top: 10px;
  }
  video.data-videoBox {
    width: 95%;
    border: 1px solid #e5e5e5;
  }
  button.link-line, a.link-line {
    color: #C73D39;
    padding: 1px 5px;
    font-weight: 600;
    background: transparent;
    border: none;
    text-decoration: none;
  }
  .link-line:hover {
    color: #6d2828;
    background: transparent !important;
    border: none;
  }
  .link-small-styles button.p-button.p-component:hover {
    background: #ffffff !important;
    border-color: #ffffff;
    color: #6d2828;
  }
  .dnFlexItem {
    padding: 5px;
    font-size: 10px;
  }
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #de6e6e transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}
.foot-item {
  padding-top: 10px;
}
.iti-title {
  font-weight: 100;
  font-style: italic;
  padding: 3px;
}
.foot-item table tr th {
  font-size: 12px;
}
.foot-item table tr td {
  font-size: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.p-button:enabled:active, .p-button:not(button):not(a):not(.p-disabled):active {
  background: #C73D39;
  border-color: #C73D39;
}
.image-area-original{
  height: 500px;
}
td.script-old-view {
  background: #f5f5f5;
}
h4.small-scriptor {
  text-align: left;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 10px;
}
.copy-display{
  text-align: center;
  padding-bottom: 25px;
}
table.strip-scnum td {
  font-size: 10px;
  font-weight: 600;
  text-align: left;
}
.script-section {
  padding: 20px 0px;
  border-bottom: 1px dotted #e3e3e3;
}
.row {
  margin-left: 0px;
  margin-right: 0px;
}
.extend-block {
  border: 1px dashed #b9b9b9;
  padding: 15px;
  margin-bottom: 15px;
}
.extend-block h4.small-text{
  font-size: 14px;
}
.small-text {
  font-size: 13px;
}
.tab-code {
  background: #ebeaea;
  cursor: pointer;
  margin-bottom: 15px;
  padding: 10px 20px;
  text-align: center;
  border-radius: 5px;
}
.tab-code:hover {
  background: #d3d3d3;
}
.search-box{
  padding: 10px 0px;
}
input.searchMain {
  background: #dfdfdf;
  padding: 10px 15px;
  border: 0px;
  width: 90%;
  border-radius: 20px;
}
.dialog-search .content-search {
  padding: 9px 16px;
  text-align: left;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
}
.dialog-search .content-search:hover {
  background: #ebe1e1;
}

.dialog-search {
  background: #fff;
  border: 1px solid #f2f2f2;
  border-radius: 11px;
  color: #000;
  height: 370px;
  margin: 15px 0;
  overflow-y: scroll;
  width: 90%;
  position: absolute;
  z-index: 1;
  box-shadow: 5px 7px 11px;
}
.dialog-search .content-search strong {
  margin-right: 8px;
}
.thin {
  font-weight: 100;
}
.smaller {
  font-size: 10px;
}
textarea.txtarea3 {
  height: 200px;
}
.fill-in-side {
  color: #414042;
  font-size: 13px;
  font-style: italic;
}
/* Chat ---> */
.chat-header {
  background: #c82429;
  color: #fff;
  padding: 15px 12px 1px;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}

.message-buble {
  padding: 10px 20px;
  font-size: 13px;
  width: 50%;
  margin: 10px;
}

.your-message{
  background: #c9ebb4;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: flex-start;
}
.others-message {
  background: #c7b9ab;
  border-top-left-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  position: relative;
  left: 42%;
}
.chat-window-body .control-chat-scroll{
  height:320px;
  width: 100%;
  overflow-y: scroll;
}
.chat-window-body .control-chat-scroll::-webkit-scrollbar{
   display: none;
}
.titling-buble {
  font-weight: 800;
  font-size: 13px;
}
.time-meta {
  margin-top: -11px;
  font-size: 10px;
  color: gray;
}
.chat-footer {
  padding: 10px;
  background: whitesmoke;
}
/* Chat End---> */
.chat-window-body {
  background: #fbfbfb;
}
.txt-message {
  width: 85%;
  float: left;
}
.inner-awaiting {
  width: 300px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
}
img.image-inner-await {
  width: 100%;
}
.pharm-content {
  text-align: center;
  height: 139px;
  background-color: #f7f6f6;
  padding-top: 25px;
  margin-bottom: 15px;
}

.p-title {
  padding: 5px;
}
.p-title.p-sub-title {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 2px;
}
table.table.tb-small td {
  font-size: 12px;
  padding: 8px;
}
.fright{
  float: right;
}
.min-request {
  border-top: 1px solid #e1e1e1;
  padding: 40px 15px;
  background: #f8f8f8;
}
ul li.groove-container {
  color: #303030;
  background: #fff6f6;
  border: 1px dashed #eb0e0e;
  padding: 15px;
}
ul li.groove-container .litext {
  font-size: 20px;
  padding: 5px;
}
.validator-buttons {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.img-source.cl-1 {
  float: right;
}
.img-source.cl-1 img.inn-process {
  width: 25px;
}
.opti-one {
  font-size: 12px;
  color:#636060;
  padding: 0px 0px 5px 0px;
}


.pricing-buttons {
  padding: 15px 0px;
}
.pricing-amount {
  border: 2px #f5eeee solid;
  padding: 10px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 600;
}
button[disabled=disabled], button:disabled {
  cursor: none;
  border: 3px solid #eaeaea !important;
  color: #d8d6d6 !important;
}
td.vatop{
  vertical-align: top;
}
.checkbox-item-left {
  float: left;
  padding-top: 7px;
  padding-right: 10px;
}
.chck-content {
  padding-top: 5px;
}
.colors-alt {
  color:#fff;
  font-weight: 900;
  cursor: pointer;
}

.dossage-work {
  padding: 10px;
  border-radius: 7px;
  border: 1px dashed rgb(227, 227, 227);
}
.dossage-work-advance {
  background: #f4f4f4;
  padding: 10px;
  border-radius: 7px;
  border: 1px dashed gray;
}
.adv-doss-list{
  height: 230px;
  margin-top: 8px;
  overflow-y: scroll;
}
.sub-sect {
  color: gray;
  font-size: 15px;
  font-weight: 100;
  text-transform: uppercase;
  padding: 10px 0px;
}
/* ////////////////// */
.view-tabular {
  background: #fcefe9;
  padding: 10px;
  border-radius: 20px;
}
/* Bubble Chat */
.chat-bubble {
  background-color:#E6F8F1;
  padding:16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display:inline-block;
}

.typing {
  align-items: center;
  display: flex;
  height: 17px;
}

.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #6CAD96 ;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;

}

.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}

.typing .dot:last-child {
  margin-right: 0;
}

@keyframes mercuryTypingAnimation {

  0% {
    transform: translateY(0px);
    background-color:#6CAD96; 
  }

  28% {
    transform: translateY(-7px);
    background-color:#9ECAB9; 
  }

  44% {
    transform: translateY(0px);
    background-color: #B5D9CB;
  }
}
/* ////////////////// */
.base-brand-image img {
  width: 90%;
}
.edit-selected {
  border: 2px solid #C73D39;
  border-radius: 7px;
  padding: 15px;
  margin-bottom: 15px;
}
.medicine-name {
  color:#C73D39;
  font-size: 16px;
}
.perifiral-content {
  color: #707070;
  padding: 13px 0px;
  font-size: 11px;
  display: flex;
}
.button-meds-content {
  background: #C73D39;
  color: #fff;
  padding: 8px 10px;
  font-size: 12px;
  font-weight: 600;
  border-radius: 18px;
}

span.checkright {
  color: #C73D39;
  background-color: #fff;
  padding: 1px 5px 3px 4px;
  border-radius: 48%;
  margin-right: 5px;
}
span.name-sp {
  padding-top: 2px;
  font-size: 15px;
  padding-right: 5px;
  font-weight: 400;
}
.item-pill {
  padding-right: 15px;
  padding-bottom: 15px;
}
.title-pill {
  font-size: 10px;
  font-weight: 500;
  padding-bottom: 3px;
}
.foot-selections-meds {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
}
.foot-selections-meds button.btn-gray-sm {
  padding: 5px 25px;
  border: 2px solid #babbbb;
  color:#fff;
  border-radius: 15px;
  background: #babbbb;
  font-size: 12px;
}
.foot-selections-meds button {
  padding: 5px 25px;
  border: 2px solid #C73D39;
  color:#C73D39;
  border-radius: 15px;
  background: transparent;
}
.foot-selections-meds button:hover {
  border: 2px solid #a4a6a8;
  color:#fff;
  background: #a4a6a8;
}
.line-input {
  display: flex;
}
button.sel-go {
  border: 0px;
  padding: 10px 15px;
  margin-right: 10px;
}
.dos-input-outer {
  padding: 10px;
  border-radius: 10px;
  border: 1px dashed #b2b2b2;
}
.dos-input-perif {
  display: flex;
}
.mditem.md1 {
  width: 25%;
}
.mditem.md2 {
  width: 20%;
}
.mditem.md3 {
  width: 10%;
}
.mditem.md4 {
  width: 10%;
  
}
.mditem.md5 {
  width: 30%;
}
.center-part {
  text-align: center;
}
input.ct-in {
  width: 100%;
  padding: 5px;
  border: 0px;
  background: #ededed;
}
input.ct-in.quantity-item {
  width: 85%;
}
input.ct-in.unit-item {
  width: 85%;
}
span.ds-line-show {
  margin-right: 5px;
}
.mditem {
  height: 70px;
}
.doseline-view {
  background: #babbbb;
  color: #fff;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 15px;
  display: inline-block;
}
button.btn.btn-save-dose {
  border: 1px solid #C73D39;
  color: #C73D39;
  margin-top: 11px;
  margin-bottom: 10px;
}
.extra-note-area .add-note {
  font-weight: 600;
  color: #9a9a9a;
}
.extra-note-area {
  padding-top: 19px;
  padding-bottom: 10px;
}
.extra-note-area button {
  background: #C73D39;
  border: 0px;
  padding: 2px 10px 4px 10px;
  margin-top: 2px;
}
.extra-note-area button:hover {
  background: #C73D39;
}
ul.med-notes {
  list-style: none;
  padding: 0px;
  font-size: 12px;
  display: flex;
}
.search-box-view {
  background: #f7f7f7;
  padding: 15px;
  height: 420px;
  overflow-y: scroll;
}
.search-box-view ul.list-items-cont li {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 18px;
}
.litext {
  font-size: 19px;
  color:#C73D39;
  padding: 5px 0px;
}
li.note-item span {
  margin-right: 3px;
}
.cooreview-content {
  padding-top: 10px;
}
.cooreview-content li.note-item {
  background-color: #b2b2b2 !important;
}
input.note-input {
  font-size: 13px;
  height: 30px;
  background: #f1f1f1;
  width: 250px;
}
.short-info-peri {
  font-size: 10px;
  color: rgb(181, 181, 181);
}
.short-info-peri span {
  margin-right: 2px;
}
.circle-sch {
  border: 1px solid;
  padding: 5px 6px;
  border-radius: 50%;
}
.lbl-prep {
  padding: 10px 0px 0px 0px;
  height: 30px;
  font-size: 10px;
  color: gray;
}
.count-prescription {
  border: 2px solid #bcbcbc;
  margin-bottom: 5px;
  padding: 5px 35px;
  color: #bcbcbc;
  display: inline-block;
  font-weight: 600;
}
ul.med-notes li.note-item {
  border: 1px solid #babbbb;
  padding: 10px;
  margin-right: 3px;
}
.script-assessment {
  padding: 10px;
  margin-bottom: 15px;
  border: 1px dashed #aaa6a6;
  background: #fcfcfc;
  border-radius: 15px;
}
.script-assessment button.btn {
  background: #fcefe9;
  margin-right: 10px;
  font-size: 12px;
  margin-bottom: 10px;
  padding: 10px 20px;
  border-radius: 25px;
}
.script-assessment button.btn.active {
  background:#16a63c;
  color:#fff;
}
.script-assessment .button-group {
  margin-bottom: 13px;
  margin-top: 10px;
}
.acknowledge-script {
  font-size: 9px;
}
.form-group.sc-verify .checkbox-group span {
  padding-left: 7px;
}
.form-group.sc-verify .sm-label {
  margin-top: 15px;
}
.form-group.sc-verify .checkbox-group {
  margin-top: 15px;
}
.level-up-buttns button.pots-work {
  position: absolute;
  top: -50px;
  right: 2px;
}