@media screen and (max-width: 1350px) {

}
@media screen and (max-width: 1250px) {

}
@media screen and (max-width: 950px) {
    .full-side {
        width: 200px;
    }
    .top-nav {
        padding: 15px 10px;
        width: 68%;
    }
}
@media screen and (max-width: 500px) {
    span.name-Item {
        display: none;
    }
    .full-side {
        width: 55px !important;
    }
    img.logo-on-dashboard {
        width: 100%;
        position: relative;
    }
    .main-content-arena {
        padding: 10px;
    }
    .single-details {
        overflow-x: auto;
        white-space: nowrap;
    }
    .sect-box  .flexme {
        display: block;
    }
    .chat-area-request-chat, .adding-mask {
        width: 100%;
        overflow-x: auto;
    }
    h3.sidebarTitle.logo-spark {
        padding: 5px;
    }
    
    .sidebarMenu ul li a {
        padding: 15px 5px;
    }
}